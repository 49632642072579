.categorie-header {
    background-color: hsl(0, 0%, 100%);
    padding: 18px 20px 18px 0px;
    border-right: 2px solid #ddd;
    display: flex;
    flex-direction: column;
    width: 209px;
    min-height: 100vh;
}

.categorie-header>a {
    text-decoration: none;
    color: #5C5C5C;
}

.categorie-subtitle {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin: 10px
}

.catagery .active {
    padding-right: 25px;
    padding-left: 20px;
    color: #1B7D78;
    background-color: #1B7D78;
}

.active-link {
    color: #1B7D78;
}

.active {
    color: #1B7D78;
}

.categorie-subtitle.active {
    color: #1B7D78;
    /* border-bottom: 5px solid #1B7D78; */
    /* padding: 18px 0px; */
}

.custom-submit-button {
    background-color: #1B7D78;
    color: #ffffff;
}

.image {
    max-width: 100px;
    padding: 10px;

}

.upload-icon {
    width: 45px;
    height: 50px;
    padding-top: 5px;
}

.cat-form-field {
    display: flex;
    flex-direction: column;
}

.image-field {
    height: 200px;
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-field.active {
    height: 200px;
    width: 200px;
    border: 1px solid var(--bs-danger-text, #ff0000);;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textfile {
    text-align: center;
    /* padding-top: 20px; */
}

.justify-content-between {
    justify-content: space-between !important;
}

.table-responsive header {
    display: none;
}
.table-responsive{
    position: relative;
}
.marginTop {
    margin-top: 35px
}

/* day2 css styles */
.cat-searh {
    margin-bottom: 35px;
    height: 37px;
    top: 220px;
    left: 106px;
    border-radius: 4px;
    border: 1px;

}

.cat-button {

    border-radius: 4px;
    display: flex;
    gap: 10px;
    height: 39px;
    justify-content: end;
    /* margin: 14px 10px 10px; */
    width: Hug (105px);

}

.cat-button div {
    color: white;
    background-color: #1B7D78;
}

.cat-button.inactive div {
    color: white;
    background-color: #8C8C8C;
}

.sub-cat {
    width: 70%;
    margin-left: 170px;
    padding-left: 10px;
    padding-right: 8px;
}

.product-search {
    width: 70%;
    margin-right: 270px;
    margin-left: 100px;
    padding-left: 10px;
    padding-right: 8px;
}

.sub-filter {
    width: 25%;
    padding-left: 10px;
    padding-right: 8px;
    position: relative;
    z-index: 2;
    padding: 0px;
    /* padding-left: 15px; */
}

.product-filter {
    width: 70%;
    padding-left: 10px;
}


.product-filter,
.product-search {

    position: relative;
    z-index: 2;
}

.flex {
    display: flex;
}

.space-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 0px !important;
}

.input-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 25%;
}

.input-control {
    border: none;
    outline: none;
    width: 100%;
}

.input-subcat-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    width: 25%;
    margin-right: 15px;
    margin-left: 15px;
}

.button-pro {
    display: flex;
    justify-content: end;
}

.input-cat-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    /* width: 100%; */
    flex: 1;
    max-height: 45px;
}

.banner-form {
    width: 45%;
}

.banner-image {
    display: flex;
    justify-content: center;
}

.button-pro {
    display: flex;
    justify-content: end;
}

.child-sidenav {
    display: flex;
}

.childnav-content {
    padding: 16px 0px 16px 20px;
    width: 100%;
    height: calc(100vh - 50px);
    overflow-x: auto;
    gap: 16px;
    display: flex;
    flex-direction: column
}

.childnav-content::-webkit-scrollbar {
    display: none;
}

.table-responsive::-webkit-scrollbar {
    display: none;
}

.replace-file {
    display: flex;
}

.resolution-desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    font-family: sans-serif;
    margin: 0px 20px;
}

.replace-btn {
    border-radius: 20px;
    border: 1px solid #1B7D78;
    padding: 9px 11px;
    color: #1B7D78;
    margin-top: 20px;
}

.img-container {
    width: 118px;
    height: 118px;
    border-radius: 10px;
    background-color: #FFE4F9;
    margin-right: 20px;
    min-width: 118px;
}

.child-title {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    width: 200px;
}

.image-icon {
    text-align: center;
}

/* .childnav-name {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */