.dashboard-layout {
    display: flex;
}

header {
    position: fixed;
    width: 100%;
}

aside {
    margin-top: 50px;
    display: flex;
    flex: 1;
}

nav.sidebar-main {
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: hidden;
}

nav.sidebar-main::-webkit-scrollbar {
    display: none;
}

.dashboard-content {
    margin-top: 76px;
    flex: 15;
    /* display: flex; */
}

header {
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    z-index: 2;
}

img.DashBoard-toggle {
    max-width: 20px;
    margin-left: 33px;
}

img.DashBoard-Logo {
    max-width: 206px;
    max-height: 37px;
    margin-left: 20px;
    padding: 5px;
}

.DashBoard-Notification {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    color: #5C5C5C;
}

img.DashBoard-Photo {
    width: 30px;
    height: 30px;
    /* margin-right: 18px;
    margin-left: 25px; */
    border-radius: 50%;
}

.Dashboard-brand {
    flex: 7;
    padding: 14px;
}

.menu-right {
    /* padding-top: 12px; */
    /* flex: 1; */
    display: flex;
    align-items: center;
}

.sidebar-main {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 86px;
    padding-top: 40px;
}

.sidebar-icon {
    font-size: 24px;
}

.sidebar-name {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.sidebar-child {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    margin-left: 3px
}

.sidebar-child h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #00769B;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 58px;
    margin-bottom: 0px;
}

.Configiration-list {
    padding: 5px 20px;
}

.Configiration-list1 {
    padding: 5px 20px;
}

/* .Configiration-list:hover a .list-name {
    color: #00769B;
} */


.dashboard-content {
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 20px; */
}

.navitem:hover,
.navitem.active {
    border-left: 5px solid #1B7D78;
    margin-left: 0px;
}

.navitem:hover .sidebar-name .navitem.active .sidebar-name {
    color: #034561;
}

.sidebar-main a.navitem.active {
    color: #034561;
    border-left: 5px solid #034561;
    margin-left: 0px;
}

.navitem {
    margin-left: 5px;
}

.dashboard-header {
    display: flex;
    background: #e6e6e6;
}

.dashboard-header a {
    margin: 2px;
}

.month-selector {
    flex: 0.2;
}

.dashboard-header-doctor {
    background: #00EBE6;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    padding: 12px 15px;
}

h5.dashboard-subtitle {
    flex: 1;
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    ;
    padding-top: 8px;
}

.dashboard-header {
    padding: 12px 15px;
}

.dashboard-header-emr {
    padding: 12px 15px;
}

.Configiration-list:hover,
.Configiration-list.active {
    background: #F0F8FF;
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
    border-radius: 4px;
}

.configiration-icon {
    max-width: 14px;
    max-height: 14px;
}

.colorChange {
    color: #00769B !important;
    text-decoration: none;
}

.sidebar-icon.acitve {
    font-size: 24px;
    color: #1B7D78 !important;

}

.NoColorChange {
    color: #5C5C5C;
    text-decoration: none;
}

.list-name {
    margin-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
}

.navitem {
    text-align: center;
    cursor: pointer;
}

.container-form {
    background: #fff;
    padding: 12px 0px;
    border-radius: 5px;
}

button.btn.btn-white {
    background: #fff;
    color: #1B7D78;
    border-radius: 4px;
    border: 1px solid #1B7D78;
    min-width: 143px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.btn.btn-white:hover {
    background: #1B7D78;
    color: #fff;
    border-radius: 4px;
}

select.btn.btn-white:hover {
    background: #1B7D78;
    color: #fff;
    border-radius: 4px;
}

button.btn.btn-primary {
    background: #1B7D78;
    border-radius: 4px;
    min-width: 143px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

}

button.btn.btn-inactive {
    background: #8C8C8C;
    border-radius: 4px;
    color: white;
    min-width: 143px;
    height: 45px;
}

select.btn.btn-primary {
    background: #1B7D78;
    border-radius: 4px;
    min-width: 143px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

}

button.btn.btn-primary:hover {
    color: #1B7D78;
    background: #fff;
    border: 1px solid #1B7D78;
    border-radius: 4px;
}

.dashboarlist-left-entries {
    display: flex;
    gap: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
}

.dashboard-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 22px;
    padding-bottom: 21px;
    background: #F9FCFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.input-box {
    display: flex;
}

.dashboard-list-filter-main {
    margin-right: 28px;
    cursor: pointer;
}

.dashboard-list-filters {
    width: 18px;
    margin-left: 28px;
    height: 18px;
}

.dashboard-list-number {
    padding-right: 44px;
    border: 0.5px solid #CACACA;
    border-radius: 1px;
}

.dashboard-table-head {
    border-bottom: 0.3px solid #5C5C5C;
    font-family: 'Inter';
    padding-top: 16px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #1D273B;
}

.dashboard-tabe-data-actions {
    width: 14px;
    height: 14px;
    cursor: pointer;
    color: #1B7D78;
}

.dashboard-tabe-data-actions:hover {
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.54);
    border-radius: 50%;
}

.dashboard-table-list-main {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), -2px 0px 2px rgba(0, 0, 0, 0.1), 0px -2px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.pie-container {
    width: 391px;
    height: 391px;
}

.nav.sidebar-main a {
    text-decoration: none;
    color: #1D273B;
}

.nav.sidebar-main {
    color: #1D273B;

}

.sidebar-main a {
    color: #1D273B;
    text-decoration: none;
}

.Online-status {
    background: #1B7D78;
    color: #fff;
    padding: 0px 4px;
    border-radius: 8.5px;
    font-size: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
}

.user-status {
    display: flex;
    width: 86px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 93px;
    background: var(--Primary-Color1, #1B7D78);
    align-items: center;
    padding: 4px;
    gap: 3px;
    margin-right: 12px;
}

.user-online {
    color: var(--Secondary-Color5, #FFF);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
}

.online-store {
    margin-bottom: 0px;
    padding: 0px !important;
}

.online-toggle {
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.excel-button {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 30px;
}

.file-icon-border {
    border: none;
}

.cards-container {
    display: flex;
    justify-content: space-evenly;
}

.filter-card {
    margin-left: 16px;
    min-height: 100px;
    padding: 0;
    border-radius: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 20px 10px -10px;
    background: #1b7d78;
    color: #ffffff;
    width: 220px;
}

.card-body {
    padding: 10px 20px;
}

.card-body p {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
}

.clr-positive {
    color: #a6ff4e;
}

.clr-negative {
    color: #ffe200;
}

.card-heading{
    border-bottom: 1px solid #e6fffe;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 5px 0px;
}